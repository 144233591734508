import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fc4e85dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "atc-dropdown-box" }
const _hoisted_2 = ["value", "placeholder", "disabled"]
const _hoisted_3 = {
  key: 0,
  class: "atc-dropdown-dropdown"
}
const _hoisted_4 = {
  key: 0,
  class: "no-result"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["onMousedown"]
const _hoisted_7 = ["title"]
const _hoisted_8 = { class: "sub-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ui_scroll = _resolveComponent("ui-scroll")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["atc-dropdown", { focus: _ctx.focus, disabled: _ctx.disabled, 'read-only': _ctx.readOnly }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", {
        value: _ctx.searchInput,
        onInput: _cache[0] || (_cache[0] = ($event: any) => {_ctx.input = $event.target.value;}),
        placeholder: _ctx.computedPlaceholder,
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focus = true)),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focus = false)),
        class: "atc-dropdown-input",
        disabled: _ctx.disabled || _ctx.readOnly
      }, null, 40, _hoisted_2),
      (!_ctx.disabled && !_ctx.readOnly)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "atc-dropdown-icon",
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => ((!_ctx.focus && _ctx.model.id) ? (_ctx.model = {}) : ''), ["stop"]))
          }, [
            _createVNode(_component_font_awesome_icon, {
              class: _normalizeClass((!_ctx.focus && _ctx.model.id) ? 'remove' : 'search'),
              icon: ['fal', (!_ctx.focus && _ctx.model.id) ? 'times' : 'angle-down']
            }, null, 8, ["class", "icon"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.focus)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (!_ctx.items.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Ingen resultat"))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_ui_scroll, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: `item-${i}`,
                        class: _normalizeClass(["dropdown-item", { selected: _ctx.isSelected(item.id) }]),
                        onMousedown: ($event: any) => (_ctx.selectItem(item))
                      }, [
                        _createElementVNode("div", {
                          class: "title",
                          title: item.title
                        }, _toDisplayString(item.title), 9, _hoisted_7),
                        _createElementVNode("div", _hoisted_8, _toDisplayString(item.code), 1)
                      ], 42, _hoisted_6))
                    }), 128))
                  ]),
                  _: 1
                })
              ]))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}