import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1da99276"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-combobox-box" }
const _hoisted_2 = ["value", "placeholder", "disabled"]
const _hoisted_3 = {
  key: 0,
  class: "ui-combobox-dropdown"
}
const _hoisted_4 = {
  key: 0,
  class: "no-result"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["onMousedown"]
const _hoisted_7 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ui_scroll = _resolveComponent("ui-scroll")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ui-combobox", { focus: _ctx.focus, disabled: _ctx.disabled, 'read-only': _ctx.readOnly }])
  }, [
    _createElementVNode("div", {
      class: "ui-combobox-overlay",
      tabindex: "0",
      onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setFocus && _ctx.setFocus(...args)))
    }, null, 32),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", {
        value: _ctx.searchInput,
        onInput: _cache[1] || (_cache[1] = ($event: any) => {_ctx.input = $event.target.value;}),
        ref: "ui-combobox-input",
        placeholder: _ctx.computedPlaceholder,
        onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focus = true)),
        onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.focus = false)),
        class: "ui-combobox-input",
        disabled: _ctx.disabled || _ctx.readOnly
      }, null, 40, _hoisted_2),
      (!_ctx.disabled && !_ctx.readOnly)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (!_ctx.multiple && !_ctx.focus && _ctx.model[_ctx.itemValue])
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 0,
                  class: "ui-combobox-icon remove",
                  icon: ['fal', 'times'],
                  onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.model = {}), ["stop"]))
                }))
              : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 1,
                  class: "ui-combobox-icon search",
                  icon: ['fal', 'search']
                }))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    (_ctx.focus)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (!_ctx.items.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Ingen resultat"))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_ui_scroll, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: `item-${i}`,
                        class: _normalizeClass(["dropdown-item", { selected: _ctx.isSelected(item[_ctx.itemValue]) }]),
                        onMousedown: ($event: any) => (_ctx.selectItem(item))
                      }, [
                        _renderSlot(_ctx.$slots, "item", {}, () => [
                          _createElementVNode("span", {
                            class: "title",
                            title: item[_ctx.itemText]
                          }, _toDisplayString(item[_ctx.itemText]), 9, _hoisted_7)
                        ], true)
                      ], 42, _hoisted_6))
                    }), 128))
                  ]),
                  _: 3
                })
              ]))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}