import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from '@capacitor/status-bar';
import { DEBUG } from "@/models/utils";
import CacheService from "@/services/CacheService";
import { PathType } from "@/models/cache";

export const Utils = {
  formatDate(dateString: string | undefined): string | undefined {
    if (dateString) {
      const date = new Date(dateString);

      const pad = (number: number): string => {
        let r = String(number);
        if (r.length === 1) {
          r = '0' + r;
        }
        return r;
      }
      return date.getUTCFullYear() +
        '-' + pad(date.getUTCMonth() + 1) +
        '-' + pad(date.getUTCDate()) +
        'T' + pad(date.getUTCHours()) +
        ':' + pad(date.getUTCMinutes()) +
        ':' + pad(date.getUTCSeconds()) +
        '.000000' +
        'Z';
    }

    return undefined;
  },

  debug(message: string, type: DEBUG) {
    const DEBUG = process.env.VUE_APP_DEBUG === 'true';
    if (DEBUG) {
      console.log(`%c ${message}`, `color:${type}`);
    }
  },

  async base64FromPath(path: string): Promise<string> {
    const response = await fetch(path);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject('method did not return a string');
        }
      };
      reader.readAsDataURL(blob);
    });
  },

  async toggleStatusBarTheme(state: boolean): Promise<void> {
    const platform = Capacitor.getPlatform();

    if (platform === 'ios') {
      await StatusBar.setStyle({
        style: state ? Style.Dark : Style.Light,
      });
    }
    
    if (platform === 'android') {
      await StatusBar.setBackgroundColor({ color: '#eb3d47' });
    }
  },

  async cacheImage(imageId: string, imagePath: string): Promise<string> {
    let image_src = '';
    const file = await CacheService.read(`${imageId}`, PathType.IMAGE);

    if (file) {
      image_src = file;
    } else {
      await CacheService.write(imagePath, `${imageId}`, PathType.IMAGE);
      image_src = imagePath;
    }

    return image_src;
  },

  convertToEscapeCharacter(string: string | null): string | null {
    return string ? string.replace(/'/g, '\'\'') : string;
  },

  getRequestTimeout(): number {
    const seconds = 30;
    return seconds * 1000;
  },
}
