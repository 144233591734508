import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-056379e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pre-loader" }
const _hoisted_2 = {
  key: 0,
  class: "loader"
}
const _hoisted_3 = { class: "status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_img, {
      class: _normalizeClass(`preloader-image ${_ctx.progress === 100 ? 'big' : ''}`),
      src: require('@/assets/images/logo.png')
    }, null, 8, ["class", "src"]),
    _createVNode(_Transition, { onBeforeLeave: _ctx.onLeave }, {
      default: _withCtx(() => [
        (_ctx.progress !== 100)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_ion_progress_bar, {
                color: "light",
                value: _ctx.progress/100
              }, null, 8, ["value"]),
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.progress) + " % ", 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["onBeforeLeave"])
  ]))
}